import Axios from 'axios';
import Cookies from 'js-cookie';

const API_BASE = process.env.REACT_APP_API_BASE;

const defaultAxiosOpts = {
  baseURL: API_BASE,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
};

// modify defaults if running in dev mode
if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'production') {
  if (process.env.REACT_APP_API_BASIC_AUTH) {
    defaultAxiosOpts.withCredentials = false;
    defaultAxiosOpts.headers.Authorization =
      process.env.REACT_APP_API_BASIC_AUTH;
  }

  if (process.env.REACT_APP_API_SESSION_TENANT_NAME)
    Cookies.set(
      'SessionTenantName',
      process.env.REACT_APP_API_SESSION_TENANT_NAME,
    );
}

const axiosInstance = Axios.create(defaultAxiosOpts);

export const getTagsByAssetIds = async (assetIdList) => {
  try {
    const body = {
      AssetIds: assetIdList,
      TenantName: Cookies.get('SessionTenantName'),
    };
    let response = await axiosInstance.post('/tags/find', body);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getTagsByAssetNumbers = async (assetNumbers) => {
  try {
    const body = {
      AssetNumbers: assetNumbers,
      TenantName: Cookies.get('SessionTenantName'),
    };
    let response = await axiosInstance.post('/tags/find', body);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getTagsWithFilter = async (filterObject) => {
  try {
    const body = {
      TagQuery: filterObject,
      TenantName: Cookies.get('SessionTenantName'),
    };
    let response = await axiosInstance.post('/tags/find', body);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const saveTag = async (tag) => {
  try {
    const body = {
      ...tag,
      TenantName: Cookies.get('SessionTenantName'),
    };
    return axiosInstance.post('/tags/save', body);
  } catch (err) {
    throw new Error(err);
  }
};

export const saveTags = async (tagList) => {
  try {
    let tagSavePromises = [];
    for (const tag of tagList) {
      let promise = saveTag(tag);
      tagSavePromises.push(promise);
    }
    const proms = await Promise.allSettled(tagSavePromises);
    return proms;
  } catch (err) {
    throw new Error(err);
  }
};

export const saveTagList = async (tagList) => {
  try {
    const body = {
      ...tagList,
      TenantName: Cookies.get('SessionTenantName'),
    };
    return axiosInstance.post('/taglists/save', body);
  } catch (err) {
    throw new Error(err);
  }
};

export const saveTagListAndTags = async (tagList, tags) => {
  try {
    const tagLayersSaved = await saveTagLayers(tags);
    const tagLayerIds = tagLayersSaved.map((tagLayerResponse) => {
      return tagLayerResponse.value.data.TagLayerId;
    });
    const body = {
      ...tagList,
      TagLayers: tagLayerIds,
      TenantName: Cookies.get('SessionTenantName'),
    };
    return axiosInstance.post('/taglists/save', body);
  } catch (err) {
    throw new Error(err);
  }
};

export const getTagListById = async (tagListId) => {
  try {
    let response = await axiosInstance.get(
      `/taglists/${tagListId}?TenantName=${Cookies.get('SessionTenantName')}`,
    );
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getAllTagListNames = () => {
  try {
    let response = axiosInstance.get(
      `/taglists/available?TenantName=${Cookies.get('SessionTenantName')}`,
    );
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const saveTagLayer = async (tagLayer) => {
  try {
    const body = {
      ...tagLayer,
      TenantName: Cookies.get('SessionTenantName'),
    };
    return axiosInstance.post('/taglayers/save', body);
  } catch (err) {
    throw new Error(err);
  }
};

export const saveTagLayers = async (tagLayerList) => {
  try {
    let tagSavePromises = [];
    for (const tagLayer of tagLayerList) {
      let promise = saveTagLayer(tagLayer);
      tagSavePromises.push(promise);
    }
    const proms = await Promise.allSettled(tagSavePromises);
    return proms;
  } catch (err) {
    throw new Error(err);
  }
};

export const deployTagToAsset = (tag, assetId, assetNumber) => {
  try {
    let body = {
      ...tag,
      TenantName: Cookies.get('SessionTenantName'),
    };
    if (body.TagId) {
      delete body.TagId;
    } else if (body.TagLayerId) {
      delete body.TagLayerId;
    }
    body.AssetId = assetId;
    body.AssetNumber = assetNumber;
    body.IsDirty = true;
    if (body.DisplayFormat) {
      // Value for DisplayFormat will get set by the backend
      delete body.DisplayFormat;
    }
    if (body.StatusAlarmLookupId) {
      body.StatusAlarmLookupId = parseInt(body.StatusAlarmLookupId);
    }
    return axiosInstance.post('/tags/save', body);
  } catch (err) {
    throw new Error(err);
  }
};

export const deployTagsToAssets = async (deployList, tags, setDeployList) => {
  try {
    let tagSavePromises = [];
    for (const deploy of deployList) {
      let promise = deployTagToAsset(
        tags[deploy.TagIndex],
        deploy.AssetId,
        deploy.AssetNumber,
      );
      tagSavePromises.push(promise);
    }
    const proms = await Promise.allSettled(tagSavePromises);
    let newDeployList = [...deployList];
    proms.forEach((prom, index) => {
      if (prom.status === 'rejected') {
        newDeployList[index].DeployStatus = 'Failed';
        newDeployList[index].ErrorMessage = prom.reason;
      } else {
        newDeployList[index].DeployStatus = 'Complete';
      }
    });
    setDeployList(newDeployList);
    return proms;
  } catch (err) {
    throw new Error(err);
  }
};

export const getAssetsWithFilter = async (filterObject) => {
  try {
    const body = {
      TagQuery: filterObject,
      TenantName: Cookies.get('SessionTenantName'),
    };
    let response = await axiosInstance.post('/assets/find', body);
    // Need assetId and assetNumber
    const idsOnly = response.data.Assets.map((asset) => {
      return {
        AssetId: asset.AssetId,
        AssetNumber: asset.AssetNumber,
      };
    });
    return idsOnly;
  } catch (err) {
    throw new Error(err);
  }
};

export const getLookupValues = async () => {
  try {
    const body = {
      TenantName: Cookies.get('SessionTenantName'),
      LookupNames: [
        'TagType',
        'TagSourceType',
        'TagAlarmType',
        'AlarmSeverity',
        'GaugeType',
        'TagDataType',
        'NotificationPriority',
        'AlarmArmingType',
        'AlarmLimitRange',
      ],
      IncludeAssociations: ['Lookup'],
      OrderBy: 'DisplayName',
    };
    let response = axiosInstance.post('/lookups/find', body);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getAllCodeLegends = async () => {
  try {
    let response = axiosInstance.get(
      `/codeLegends/available?TenantName=${Cookies.get('SessionTenantName')}`,
    );
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getCodeLegendById = async (id) => {
  try {
    let response = await axiosInstance.get(
      `/codeLegends/${id}?TenantName=${Cookies.get('SessionTenantName')}&IncludeAssets=false`,
    );
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getCodeLegendEntriesById = async (id) => {
  try {
    const response = await getCodeLegendById(id);
    return response.data.Entries;
  } catch (err) {
    throw new Error(err);
  }
};
